import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { getSrc, getImage, GatsbyImage } from 'gatsby-plugin-image'
import Hero from '../components/Hero'
import { BookCover } from 'book-cover-3d'
import ConvertKit from 'convertkit-react'

const BookLaunch = ({ data }) => {
  const src = getSrc(data.file)
  const image = getImage(data.file)
  return (
    <Layout>
      <SEO
        title="The Art of the Subtle Subtweet"
        description="A new book by Laurieontech!"
        imageSource={src}
        imageAlt="The book cover, featuring an illustrated phone."
      />
      <Hero
        title="ANNOUNCING MY BOOK LAUNCH"
        description="An exciting addition to my existing content endeavors"
      />
      <section id="about" className="special wrapper container style4 post">
        <p className="bio p book">
          I'm incredibly excited to become an author! It's honestly something I
          said I'd never do, but when{' '}
          <a href="https://twitter.com/amber1ey">Amberley Romo</a> approached me
          with this topic it was such a natural fit. So I'm thrilled to announce
          my new book, The Art of the Subtle Subtweet.
        </p>
        <div className="book-cover">
          <BookCover
            pagesOffset={0}
            thickness={23}
            bgColor={`white`}
            shadowColor={`#949494`}
            height={300}
          >
            <GatsbyImage
              image={image}
              alt="The Art of the Subtle Subtweet cover."
            />
          </BookCover>
        </div>
        <p className="bio p book">
          This book is chock full of everything you need to become a subtweet
          artist.
          <ul className="superstar">
            <li>Waiting the perfect amount of time</li>
            <li>Picking your subjects</li>
            <li>Plausible deniability</li>
          </ul>
          Not to mention, a deep dive into my greatest subtweet hits. You'll get
          exclusive access to all the details, including who they're about!
        </p>
        <p className="bio p book">
          When you're done reading, you too will have people asking "was that a
          subtweet?". I hope I've piqued your interest! More details on a
          publish date coming soon. You won’t want to miss this so be sure to
          join the mailing list below.
        </p>
        <ConvertKit className="book-button" formId="2109946" />
        <p className="bio p book">
          Ready to take tech twitter by storm? Join me.
        </p>
        <p className="bio p book">
          Yours Truly, <br />
          Lady Whistledown
        </p>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "subtweet.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default BookLaunch
